import minBy from 'lodash-es/minBy';
import styled from 'styled-components';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import {
  getLinearScale,
} from '../../NonConstantUtils';
import {
  earliestHSYear,
  earliestSITCYear,
  latestYear,
} from '../../Utils';
import { exploreGray } from '../../viz/VizGrid';

export const yearCursorTweenDuration = 350; // in milliseconds

// `yearPaddign` is needed so that the user can reliably click/drag the cursor to get to
// the first/last year. Because we constrain the year cursor to be bounded by
// this component's root container, if the first and last years are positioned
// flush against the borders of the root container, it can sometimes be
// impossible to select those years:
export const getYearInfoForProductClass = (productClass: ProductClass) =>
  (productClass === ProductClass.HS) ?
    ({minYear: earliestHSYear, maxYear: latestYear, yearPadding: 0.49}) :
    ({minYear: earliestSITCYear, maxYear: latestYear, yearPadding: 1.3});

export const getPercentageScaleInfo = (productClass: ProductClass) => {
  const {minYear, maxYear, yearPadding} = getYearInfoForProductClass(productClass);

  const scale = getLinearScale([minYear - yearPadding, maxYear + yearPadding], [0, 100]);
  return {scale, minYear, maxYear};
};

export interface ICoord {
  year: number;
  x: number;
}

export const findClosestPoint =
  (dataPoints: ICoord[], desiredX: number): ICoord =>
    minBy(dataPoints, ({x}) => Math.abs(x - desiredX))!;

/* Start of  styled-components */
const backgroundColor = 'rgb(225, 228, 233)';
const foregroundColor = exploreGray;
const tickColor = 'rgb(180, 180, 180)';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 3fr 1fr 4fr;
  font-size: 0.8rem;
`;

export const Slider = styled.div`
  --width: 100%;

  width: var(--width);
  margin: 0 auto;
  grid-row: 2;
  grid-column: 1;
  position: relative;
  background-color: ${backgroundColor};
  cursor: pointer;
  border-radius: 0.5rem;
`;

export const YearRangeIndicator = styled.div`
  --left: 0;
  --right: 0;
  --vertical-separation-from-edge: 30%;

  position: absolute;
  top: var(--vertical-separation-from-edge);
  bottom: var(--vertical-separation-from-edge);
  left: var(--left);
  right: var(--right);
  background-color: rgb(155, 168, 180);
  opacity: 0;
`;

export const TicksContainer = styled.div`
  grid-row: 2 / 4;
  grid-column: 1;
  position: relative;
  pointer-events: none;
`;

export const Cursor = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  color: white;
`;

export const Marker = styled.div`
  position: absolute;
  width: 3px;
  top: 37%;
  bottom: 50%;
  transform: translateX(-50%);
  background-color: ${foregroundColor};
`;

const borderRadius = 0.2; // in rem
const arrowWidth = 0.5; // in rem
const arrowEdgeRelativeToLabel = 0.13; // in rem;
const labelOffset = 0.6; // in rem;

const YearDisplayBase = styled.div`
  top: 5%;
  position: absolute;
  background-color: ${foregroundColor};
  padding: 0.25rem 0.5rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius}rem;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    border-left: ${arrowWidth}rem solid transparent;
    border-right: ${arrowWidth}rem solid transparent;
    border-top: ${arrowWidth}rem solid ${foregroundColor};
  }
`;

export const EndYearDisplay = styled(YearDisplayBase)`
  left: ${- labelOffset}rem;

  &::after {
    left: ${arrowEdgeRelativeToLabel}rem;
  }
`;

export const StartYearDisplay = styled(YearDisplayBase)`
  right: ${- labelOffset}rem;

  &::after {
    right: ${arrowEdgeRelativeToLabel}rem;
  }
`;

export const MajorTickMark = styled.div`
  --percentage-from-left: 0;

  position: absolute;
  top: 0.1rem;
  height: 1.2rem;
  width: 1px;
  left: var(--percentage-from-left);
  transform: translateX(-50%);
  background-color: #999;
`;

export const MinorTickMark = styled.div`
  --percentage-from-left: 0;

  position: absolute;
  top: 0.1rem;
  height: 0.6rem;
  width: 1px;
  left: var(--percentage-from-left);
  transform: translateX(-50%);
  background-color: ${tickColor};
`;

export const TickLabel = styled.div`
  --percentage-from-left: 0;

  position: absolute;
  top: 1.5rem;
  transform: translateX(-50%);
  left: var(--percentage-from-left);
  color: ${tickColor};
`;

/* End of styled-components */

import Draggable from 'gsap/Draggable';
import TimelineLite from 'gsap/TimelineLite';
import TweenLite from 'gsap/TweenLite';
import noop from 'lodash-es/noop';
import range from 'lodash-es/range';
import React from 'react';
import {ProductClass} from '../../graphQL/graphQLTypes';
import memoize from '../../memoize';
import {
  getLinearScale,
} from '../../NonConstantUtils';
import resizeMeasurementListener, {
  IListener,
} from '../../ResizeMeasurementListener';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import Ticks from './Ticks';
import {
  Cursor,
  EndYearDisplay,
  findClosestPoint,
  getPercentageScaleInfo as unmemoizedGetPercentageScaleInfo,
  getYearInfoForProductClass,
  ICoord,
  Marker,
  Root,
  Slider,
  StartYearDisplay,
  yearCursorTweenDuration,
  YearRangeIndicator,
} from './Utils';

const getPercentageScaleInfo = memoize(unmemoizedGetPercentageScaleInfo);

export enum TimelineType {
  SingleYear = 'SingleYear',
  YearRange = 'YearRange',
}

interface ISingleYearProps {
  type: TimelineType.SingleYear;
  year: number;
  onYearChange: (year: number) => void;
}

interface IYearRangeProps {
  type: TimelineType.YearRange;
  endYear: number;
  startYear: number;
  onEndYearChange: (year: number) => void;
  onStartYearChange: (year: number) => void;
}

enum Side {
  Left = 'Left',
  Right = 'Right',
}
type IYearProps = ISingleYearProps | IYearRangeProps;

type IProps = {
  productClass: ProductClass;
} & IYearProps;

interface IStateSingleYear {
  mirroredType: ISingleYearProps['type'];
  endYear: ISingleYearProps['year'];
  startYear: undefined;
  onYearChange: ISingleYearProps['onYearChange'];
}

interface IStateYearRange {
  mirroredType: IYearRangeProps['type'];
  startYear: IYearRangeProps['startYear'];
  endYear: IYearRangeProps['endYear'];
  onEndYearChange: IYearRangeProps['onEndYearChange'];
  onStartYearChange: IYearRangeProps['onStartYearChange'];
}
type IState = {
  mirorredProductClass: ProductClass;
} & (IStateSingleYear | IStateYearRange);

interface CleanUpStartYearDraggableInfo {
  shouldCleanUp: boolean;
  startYearDraggable: any;
}

type CleanUpRangeIndicatorInfo = {
  shouldCleanUp: false,
} | {
  shouldCleanUp: true;
  rangeIndicatorEl: HTMLElement | null
};
interface IComponentSnapshot {
  cleanUpStartYearDraggable: CleanUpStartYearDraggableInfo;
  cleanUpRangeIndicator: CleanUpRangeIndicatorInfo;
}

export default class extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let state: IState;
    if (props.type === TimelineType.SingleYear) {
      state = {
        mirorredProductClass: props.productClass,
        mirroredType: TimelineType.SingleYear,
        startYear: undefined,
        endYear: props.year,
        onYearChange: props.onYearChange,
      };
    } else if (props.type === TimelineType.YearRange) {
      state = {
        mirorredProductClass: props.productClass,
        mirroredType: TimelineType.YearRange,
        startYear: props.startYear,
        endYear: props.endYear,
        onStartYearChange: props.onStartYearChange,
        onEndYearChange: props.onEndYearChange,
      };
    } else {
      failIfValidOrNonExhaustive(props, 'Invalid timeline type');
      // These lines will never execute:
      state = {} as any;
    }
    this.state = state;

  }
  private rootEl: HTMLElement | null = null;
  private rememberRootEl = (el: HTMLElement | null) => this.rootEl = el;

  private endYearCursorEl: HTMLElement | null = null;
  private rememberEndYearCursorEl = (el: HTMLElement | null) => this.endYearCursorEl = el;

  private endYearTextEl: HTMLElement | null = null;
  private rememberEndYearTextEl = (el: HTMLElement | null) => this.endYearTextEl = el;

  private startYearTextEl: HTMLElement | null = null;
  private rememberStartYearTextEl = (el: HTMLElement | null) => this.startYearTextEl = el;

  private startYearCursorEl: HTMLElement | null = null;
  private rememberStartYearCursorEl = (el: HTMLElement | null) => this.startYearCursorEl = el;

  private rangeIndicatorEl: HTMLElement | null = null;
  private rememberRangeIndicatorEl = (el: HTMLElement | null) => this.rangeIndicatorEl = el;

  componentDidMount() {
    const state = this.state;
    this.initializeLayoutProperties(state, this.startYearDraggable);
    resizeMeasurementListener.addListener(this.resizeListener);
  }

  componentWillUnmount() {
    resizeMeasurementListener.removeListener(this.resizeListener);
  }

  /* Start of size measurement methods: */
  private resizeListener: IListener = this.getResizeListener();
  private getResizeListener(): IListener {

    const before = noop;

    const measure = () => {
      this.initializeLayoutProperties(this.state, this.startYearDraggable);
    };

    const after = noop;

    return {
      before,
      measure,
      after,
    };
  }
  /* End of size measurement-related methods */

  private applyBoundsToYearRangeDraggables(state: IStateYearRange) {
    const coords = this.coords;
    const distanceBetweenYears = this.distanceBetweenYears;
    const width = this.width;
    if (coords !== undefined && distanceBetweenYears !== undefined) {
      // Note: the "padding" is chosen to be 60% of the distance between two
      // adjacent years so that e.g. if the start year is 1995, the end-year
      // cursor (which approaches the start-year cursor from the right) can never
      // be closer to 1995 than to 1996 while the end-year cursor is being dragged
      // (because there's a hard "barrier" at year "1995.6"). The end result is
      // that the end-year can never end up to the left of the start year.
      const padding = distanceBetweenYears * 0.6;

      const matchedStartYearCoords = coords.find(({year}) => state.endYear === year)!;
      const matchedEndYearCoords = coords.find(({year}) => state.startYear === year)!;
      if (matchedStartYearCoords !== undefined && matchedStartYearCoords !== null
        && matchedEndYearCoords !== undefined && matchedEndYearCoords !== null) {

        const {x: maxXForStartYear} = matchedStartYearCoords;
        const {x: minXForEndYear} = matchedEndYearCoords;
        const startYearBounds = {minX: 0, maxX: maxXForStartYear - padding};
        const endYearBounds = {minX: minXForEndYear + padding, maxX: width};

        this.startYearDraggable.applyBounds(startYearBounds);
        this.endYearDraggable.applyBounds(endYearBounds);
      }

    }

  }

  // Note: `_props` isn't actually used but is added so that this method can only
  // be called in single-year mode:
  private applyBoundsToSingleYearDraggable(_state: IStateSingleYear) {
    const width = this.width;
    this.endYearDraggable.applyBounds({minX: 0, maxX: width});
  }

  // This method initializes the coords of the years, which in turn neccesitates
  // initializes the draggables and cursor position too:
  private initializeLayoutProperties(state: IState, startYearDraggable: any) {
    this.initializeCoords(state);
    this.initializeEndYearDraggable(state);

    if (state.mirroredType === TimelineType.YearRange) {
      this.initializeStartYearDraggable(startYearDraggable);
      this.initializeRangeIndicator(state);
    }

    if (state.mirroredType === TimelineType.SingleYear) {
      this.setEndYearCursorPositionToYear(state.endYear);
    } else {
      this.setEndYearCursorPositionToYear(state.endYear);
      this.setStartYearCursorPositionToYear(state.startYear);
    }

    if (state.mirroredType === TimelineType.SingleYear) {
      this.applyBoundsToSingleYearDraggable(state);
    } else {
      this.applyBoundsToYearRangeDraggables(state);
    }
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState | null {

    if (
      // If product class has changed ...
      (nextProps.productClass !== prevState.mirorredProductClass) ||
      // ... or if the type (single year vs year range) has changed ...
      (nextProps.type !== prevState.mirroredType) ||
      // ... of if the type is still single year but the year has changed ...
      (nextProps.type === TimelineType.SingleYear &&
        prevState.mirroredType === TimelineType.SingleYear && nextProps.year !== prevState.endYear) ||
      // ... of if the type is still year range but either the start or end year has changed ...
      (nextProps.type === TimelineType.YearRange && prevState.mirroredType === TimelineType.YearRange &&
        (nextProps.startYear !== prevState.startYear || nextProps.endYear !== prevState.endYear))
    ) {
        // ... then update the internal state:
      let result: IState;
      if (nextProps.type === TimelineType.SingleYear) {
        // If `startYear` is in year-range mode, reset it into single-year mode:
        result = {
          mirorredProductClass: nextProps.productClass,
          mirroredType: TimelineType.SingleYear,
          startYear: undefined,
          endYear: nextProps.year,
          onYearChange: nextProps.onYearChange,
        };
      } else if (nextProps.type === TimelineType.YearRange) {
        result = {
          mirorredProductClass: nextProps.productClass,
          mirroredType: TimelineType.YearRange,
          startYear: nextProps.startYear,
          endYear: nextProps.endYear,
          onStartYearChange: nextProps.onStartYearChange,
          onEndYearChange: nextProps.onEndYearChange,
        };
      } else {
        failIfValidOrNonExhaustive(nextProps, 'Invalid timeline type');
        // These lines will never execute:
        result = {} as any;
      }
      return result;

    } else {
      return null;
    }
  }

  getSnapshotBeforeUpdate(_prevProps: IProps, prevState: IState): IComponentSnapshot {
    const nextState = this.state;
    let cleanUpStartYearDraggable: CleanUpStartYearDraggableInfo;
    let cleanUpRangeIndicator: CleanUpRangeIndicatorInfo;
    if (prevState.mirroredType === TimelineType.YearRange && nextState.mirroredType === TimelineType.SingleYear) {
      cleanUpStartYearDraggable = {
        shouldCleanUp: true,
        startYearDraggable: this.startYearDraggable,
      };
      cleanUpRangeIndicator = {
        shouldCleanUp: true,
        rangeIndicatorEl: this.rangeIndicatorEl,
      };
    } else {
      cleanUpStartYearDraggable = {shouldCleanUp: false, startYearDraggable: this.startYearDraggable};
      cleanUpRangeIndicator = {shouldCleanUp: false};
    }

    return {
      cleanUpStartYearDraggable,
      cleanUpRangeIndicator,
    };
  }

  componentDidUpdate(_prevProps: IProps, prevState: IState, snapshot: IComponentSnapshot) {
    const nextState = this.state;

    const {
      cleanUpStartYearDraggable,
      cleanUpRangeIndicator,
    } = snapshot;

    if (cleanUpRangeIndicator.shouldCleanUp === true) {
      this.cleanUpRangeIndicator(cleanUpRangeIndicator.rangeIndicatorEl);
    }

    if (cleanUpStartYearDraggable.shouldCleanUp === true) {
      this.cleanUpStartYearDraggable(cleanUpStartYearDraggable.startYearDraggable);
    }

    // Note: Each time `componentDidUpdate` is called, only one ofthe
    // following path is expected to execute:
    if (nextState.mirorredProductClass !== prevState.mirorredProductClass) {
      this.initializeLayoutProperties(nextState, cleanUpStartYearDraggable.startYearDraggable);
    }
    // Change from year range to single year: clean up the start-year
    // draggable, and re-set the end-year draggable:
    if (prevState.mirroredType === TimelineType.YearRange &&
          nextState.mirroredType === TimelineType.SingleYear) {
      this.initializeEndYearDraggable(nextState);
      this.applyBoundsToSingleYearDraggable(nextState);
    }

    // change from single-year to year-range: reset both draggables and reset
    // start-year cursor's position:
    if (prevState.mirroredType === TimelineType.SingleYear &&
        nextState.mirroredType === TimelineType.YearRange) {
      this.initializeStartYearDraggable(cleanUpStartYearDraggable.startYearDraggable);
      this.initializeRangeIndicator(nextState);
      this.initializeEndYearDraggable(nextState);
      this.applyBoundsToYearRangeDraggables(nextState);
      this.setStartYearCursorPositionToYear(nextState.startYear);
    }

    // Year change within the "single year" type: just set the cursor's
    // position:
    if (nextState.mirroredType === TimelineType.SingleYear &&
        prevState.mirroredType === TimelineType.SingleYear) {
      this.setEndYearCursorPositionToYear(nextState.endYear);
    }
    // Year change within the "year range" type: set new limits for both
    // draggables and set both cursors' positions:
    if (nextState.mirroredType === TimelineType.YearRange && prevState.mirroredType === TimelineType.YearRange) {
      this.applyBoundsToYearRangeDraggables(nextState);
      this.setRangeIndicatorPosition(nextState.mirorredProductClass, Side.Left, nextState.startYear);
      this.setRangeIndicatorPosition(nextState.mirorredProductClass, Side.Right, nextState.endYear);

      // If end year has changed, update its cursor and the bounds of the
      // start year cursor:
      if (nextState.endYear !== prevState.endYear) {
        this.setEndYearCursorPositionToYear(nextState.endYear);
      } else if (nextState.startYear !== prevState.startYear) {
        // If start year has changed, update its cursor and the bounds of the
        // end year cursor:
        this.setStartYearCursorPositionToYear(nextState.startYear);
      }
    }

  }

  private leftOffset: number | undefined;
  private coords: ICoord[] | undefined;
  private width: number | undefined;
  private distanceBetweenYears: number | undefined;

  private initializeCoords(state: IState) {
    if (this.rootEl !== null) {

      const {width, left: leftOffset} = this.rootEl.getBoundingClientRect();
      const {mirorredProductClass: productClass} = state;
      const {minYear, maxYear, yearPadding} = getYearInfoForProductClass(productClass);

      const scale = getLinearScale([minYear - yearPadding, maxYear + yearPadding], [0, width]);
      const coords = range(minYear, maxYear + 1).map(year => ({year, x: scale(year)}));

      this.leftOffset = leftOffset;
      this.coords = coords;
      this.width = width;
      this.distanceBetweenYears = coords[1].x - coords[0].x;
    }
  }

  private initializeRangeIndicator(state: IStateYearRange & {mirorredProductClass: ProductClass}) {
    const {mirorredProductClass: productClass} = state;

    const {scale} = getPercentageScaleInfo(productClass);
    const left = `${scale(state.startYear)}%`;
    const right = `${100 - scale(state.endYear)}%`;
    TweenLite.set(this.rangeIndicatorEl, {opacity: 1, left, right});
  }

  private setRangeIndicatorPosition(productClass: ProductClass, side: Side, year: number) {
    const {scale} = getPercentageScaleInfo(productClass);
    let style: object;
    if (side === Side.Left) {
      style = {left: `${scale(year)}%`};
    } else {
      style = {right: `${100 - scale(year)}%`};
    }
    TweenLite.set(this.rangeIndicatorEl, style);
  }

  private cleanUpRangeIndicator(rangeIndicatorEl: HTMLElement | null) {
    TweenLite.set(rangeIndicatorEl, {opacity: 0});
  }

  private cleanUpEndYearDraggable() {
    if (this.endYearDraggable !== undefined) {
      this.endYearDraggable.kill();
      this.endYearDraggable = undefined;
    }
  }
  private endYearDraggable: any;
  // Keeps track of which cursor is beging dragged. Used to let the yera-range
  // indicator which side to animate:

  private initializeEndYearDraggable(state: IState) {

    const {coords: outerCoords} = this;
    if (outerCoords !== undefined) {
      this.cleanUpEndYearDraggable();

      this.endYearDraggable = new Draggable(this.endYearCursorEl, {
        type: 'x',
        liveSnap: outerCoords.map(({x}) => x),

        onDrag: () => {
          const coords = this.coords;
          const width = this.width;
          if (coords !== undefined && width !== undefined) {
            const relativeCoord = this.endYearDraggable.x;
            const closestPoint = findClosestPoint(coords, relativeCoord);
            const newEndYear = closestPoint.year;

            const percentage = closestPoint.x / width * 100;
            if (state.mirroredType === TimelineType.YearRange && this.rangeIndicatorEl !== null) {
              TweenLite.set(this.rangeIndicatorEl, {right: `${100 - percentage}%`});
            }
            this.setState({endYear: newEndYear});
          }
        },
        onDragEnd: () => {
          const coords = this.coords;
          if (coords !== undefined) {
            const relativeCoord = this.endYearDraggable.x;
            const closestPoint = findClosestPoint(coords, relativeCoord);
            const newEndYear = closestPoint.year;
            if (this.state.mirroredType === TimelineType.SingleYear) {
              this.state.onYearChange(newEndYear);
            } else {
              // Ensure the end-year cursor is not to the left of the start-year
              // cursor:
              this.state.onEndYearChange(newEndYear);
            }
          }
        },
      });

    }
  }

  private cleanUpStartYearDraggable(startYearDraggable: any) {
    if (startYearDraggable !== undefined) {
      startYearDraggable.kill();
      startYearDraggable = undefined;
    }
  }

  private startYearDraggable: any;
  private initializeStartYearDraggable(startYearDraggable: any) {

    const outerCoords = this.coords;
    if (outerCoords !== undefined) {
      this.cleanUpStartYearDraggable(startYearDraggable);

      this.startYearDraggable = new Draggable(this.startYearCursorEl, {
        type: 'x',
        liveSnap: outerCoords.map(({x}) => x),

        onDrag: () => {
          const coords = this.coords;
          const width = this.width;
          if (coords !== undefined && width !== undefined) {
            const relativeCoord = this.startYearDraggable.x;
            const closestPoint = findClosestPoint(coords, relativeCoord);
            const newStartYear = closestPoint.year;

            const percentage = closestPoint.x / width * 100;
            if (this.rangeIndicatorEl !== null) {
              TweenLite.set(this.rangeIndicatorEl, {left: `${percentage}%`});
            }
            this.setState((prevState: IState): IState => {
                if (prevState.mirroredType === TimelineType.SingleYear) {
                  // This branch should never happen because the start year handle is only
                  // visible when the timeline is in "year range" mode:
                  return prevState;
                } else if (prevState.mirroredType === TimelineType.YearRange) {
                  return {
                    ...prevState,
                    startYear: newStartYear,
                  };
                } else {
                  failIfValidOrNonExhaustive(prevState, 'Invalid timeline type');
                  // These lines will never be executed:
                  return prevState;
                }
              },
            );
          }
        },

        onDragEnd: () => {
          const coords = this.coords;
          if (coords !== undefined) {
            const relativeCoord = this.startYearDraggable.x;
            const closestPoint = findClosestPoint(coords, relativeCoord);
            const closestYear = closestPoint.year;
            if (this.state.mirroredType === TimelineType.YearRange) {
              this.state.onStartYearChange(closestYear);
            }
          }
        },
      });
    }
  }

  private setEndYearCursorPositionToYear(yearToSet: number) {
    const coords = this.coords;
    if (coords !== undefined && coords.length > 0 && this.endYearCursorEl !== null) {
      const {x} = coords.find(({year}) => year === yearToSet)!;
      TweenLite.set(this.endYearCursorEl, {
        x: `${x}px`,
        onComplete: this.endYearDraggable.onUpdate,
      });
    }
  }

  private setStartYearCursorPositionToYear(yearToSet: number) {
    const coords = this.coords;
    if (coords !== undefined && coords.length > 0 && this.startYearCursorEl !== null) {
      const matchedCoord = coords.find(({year}) => year === yearToSet)!;
      if (matchedCoord !== undefined && matchedCoord !== null) {
        const {x} = matchedCoord;
        TweenLite.set(this.startYearCursorEl, {
          x: `${x}px`,
          onComplete: this.startYearDraggable.onUpdate,
        });
      }
    }
  }

  private onSliderClick = (e: React.MouseEvent<any>) => {
    const leftOffset = this.leftOffset;
    const coords = this.coords;
    const props = this.props;
    const width = this.width;

    if (leftOffset !== undefined && coords !== undefined && width !== undefined) {
      // First, figure out which year was clicked on:
      const relativeCoord = e.clientX - leftOffset;
      const closestPoint = findClosestPoint(coords, relativeCoord);
      const newYear = closestPoint.year;
      const newX = closestPoint.x;

      type IndicatorTween = {
        tweenIndicator: false,
      } | {
        tweenIndicator: true; side: Side;
      };

      const tweenDuration = yearCursorTweenDuration / 1000;

      // Then figure out which cursor to tween:
      if (leftOffset !== undefined && this.endYearCursorEl !== null) {
        let cursorElementToTween: HTMLElement | null;
        let draggableUpdateCallback: any;
        let onCompleteCallback: (year: number) => void;
        let textElementToTween: HTMLElement | null;
        let indicatorTweenInfo: IndicatorTween;

        // For single-year timeline, just move the cursor to the clicked position:
        if (props.type === TimelineType.SingleYear) {
          cursorElementToTween = this.endYearCursorEl;
          draggableUpdateCallback = this.endYearDraggable.onUpdate;
          onCompleteCallback = props.onYearChange;
          textElementToTween = this.endYearTextEl;
          indicatorTweenInfo = {tweenIndicator: false};

        } else {
          // For year-range timeline, need to see how the clicked position relates to the 2 cursors:
          const {endYear, startYear} = props;

          // If the clicked year is the right of the current end-year, move the
          // end-year to the right:
          if (newYear > endYear) {
            cursorElementToTween = this.endYearCursorEl;
            draggableUpdateCallback = this.endYearDraggable.onUpdate;
            onCompleteCallback = props.onEndYearChange;
            textElementToTween = this.endYearTextEl;
            indicatorTweenInfo = {tweenIndicator: true, side: Side.Right};

          } else if (newYear < startYear) {
            // Vice versa if clicked year < start year:
            cursorElementToTween = this.startYearCursorEl;
            draggableUpdateCallback = this.startYearDraggable.onUpdate;
            onCompleteCallback = props.onStartYearChange;
            textElementToTween = this.startYearTextEl;
            indicatorTweenInfo = {tweenIndicator: true, side: Side.Left};

          } else if (newYear > startYear && newYear < endYear) {
            // If the clicked position is in between, move the the cursor that is
            // the closest to the clicked year. If tied, move the the end-year
            // cursor:
            if (Math.abs(newYear - endYear) <= Math.abs(newYear - startYear)) {
              cursorElementToTween = this.endYearCursorEl;
              draggableUpdateCallback = this.endYearDraggable.onUpdate;
              onCompleteCallback = props.onEndYearChange;
              textElementToTween = this.endYearTextEl;
              indicatorTweenInfo = {tweenIndicator: true, side: Side.Right};
            } else {
              cursorElementToTween = this.startYearCursorEl;
              draggableUpdateCallback = this.startYearDraggable.onUpdate;
              onCompleteCallback = props.onStartYearChange;
              textElementToTween = this.startYearTextEl;
              indicatorTweenInfo = {tweenIndicator: true, side: Side.Left};
            }
          } else {
            cursorElementToTween = null;
            indicatorTweenInfo = {tweenIndicator: false};
            textElementToTween = null;
          }
        }

        // Construct the tween sequence:
        if (cursorElementToTween !== null || indicatorTweenInfo.tweenIndicator === true) {
          const timeline = new TimelineLite();

          if (cursorElementToTween !== null) {
            timeline.to(cursorElementToTween, tweenDuration, {
              x: `${newX}px`,
              onUpdate: draggableUpdateCallback,
              onComplete: () => onCompleteCallback(newYear),
            });
          }

          if (textElementToTween !== null) {
            const yearStorage = {year: parseInt(textElementToTween.innerHTML, 10)};

            const onUpdate = () => textElementToTween!.innerHTML = Math.round(yearStorage.year).toString();

            timeline.to(yearStorage, tweenDuration, {year: newYear, onUpdate}, 0);
          }

          if (indicatorTweenInfo.tweenIndicator === true && this.rangeIndicatorEl !== null) {
            let tweenInput: any;
            if (indicatorTweenInfo.side === Side.Left) {
              tweenInput = {left: `${newX / width * 100}%`};
            } else {
              tweenInput = {right: `${(1 - newX / width) * 100}%`};
            }
            timeline.to(this.rangeIndicatorEl, tweenDuration, tweenInput, 0);
          }
        }
      }

    }

  }

  render() {
    const state = this.state;
    const {endYear, mirorredProductClass} = state;

    let startYearCursor: JSX.Element | null;
    if (this.state.mirroredType === TimelineType.SingleYear) {
      startYearCursor = null;
    } else {
      startYearCursor = (
        <Cursor ref={this.rememberStartYearCursorEl}>
          <Marker/>
          <StartYearDisplay ref={this.rememberStartYearTextEl}>
            {state.startYear}
          </StartYearDisplay>
        </Cursor>
      );
    }

    return (
      <Root ref={this.rememberRootEl}>
        <Slider onClick={this.onSliderClick}>
          <YearRangeIndicator ref={this.rememberRangeIndicatorEl}/>
        </Slider>
        <Ticks productClass={mirorredProductClass}/>
        <Cursor ref={this.rememberEndYearCursorEl}>
          <Marker/>
          <EndYearDisplay ref={this.rememberEndYearTextEl}>{endYear}</EndYearDisplay>
        </Cursor>
        {startYearCursor}
      </Root>
    );
  }
}
